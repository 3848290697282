<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            IESA Arts and Culture - интернациональная школа искусств в Париже. 
            IESA, расположенная в самом сердце Парижа, предоставляет специализированные практические знания по управлению культурными мероприятиями и арт-рынку.
            Студенты могут посещать занятия и лекции в музеях и исторических местах, а их образование обогащается близостью к основным произведениям искусства и культурным мероприятиям.
            <br>
            IESA INTERNATIONAL предлагает студентам профессиональные программы бакалавриата и магистратуры в области арт-бизнеса на английском языке, утвержденные французским правительством. Эти программы на английском языке предназначены для студентов, заинтересованных в развитии следующих профессиональных навыков и знаний:
            Знание арт-рынка (французский и международный) <br>
            Знание истории искусства <br>
            Маркетинг и цифровой маркетинг <br>
            Покупка, продажа и коллекционирование произведений искусства <br>
            Кураторское искусство <br>
            Монтажные выставки <br>
            Управление произведениями искусства <br>
            Управление культурным наследием и т..д.<br>
            Креативность и открытость к художественной деятельности воспитываются на курсах по рисунку, живописи, моделированию, истории портретной живописи и, следовательно, красоты на протяжении веков.
            Обучение английскому языку, и особенно лексике индустрии макияжа, способствует интеграции студентов в мультикультурные команды во время международных кинопроизводств или показов мод.
            Семинары по чтению и анализу сценария позволяют студентам более профессионально подготовиться к съемке.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=Gi_88PArgMA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            Fashion & design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="pam">Bachelor Program in Arts Management</option>
                                <option value="acm">MBA in Arts and Cultural Management</option>
                                <option value="alm">MBA in Art and Luxury Management</option>
                                <option value="macm">MBA Art Collecting Management</option>
                                <option value="ca">MBA in Contemporary Art : Sales, Display and Collecting.</option>
                                <option value="pam">MBA in Performing Arts Management & Cultural Industries</option>
                                <option value="pg">Подготовительный год на французском языке - Сорбонна</option>
                                <option value="lu">Летняя учеба в Париже: французское искусство и его рынки</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'pam'" id="pam">
                        <template #title>
                            Bachelor Program in Arts Management 
                        </template>
                        <template #description>
                            <p>
                                Эта программа предназначена для студентов со всего мира, которые хотят получить опыт жизни в Париже и узнать об истории, культуре и искусстве Франции, а также приобрести профессиональные навыки консультантов-профессионалов в области культуры, арт-дилеров и коллекционеров.
                            </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            английский 
                        </template>
                        <template #cost>
                            9.100 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, среднее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'acm'" id="acm">
                        <template #title>
                            MBA in Arts and Cultural Management
                        </template>
                        <template #description>
                            <p>
                                Программа предназначена для обучения менеджеров французских и международных культурных компаний и учреждений. 
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            18.000 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, высшее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь, январь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'alm'" id="alm">
                        <template #title>
                            MBA in Art and Luxury Management
                        </template>
                        <template #description>
                            <p>
                                Профессиональное обучение управлению и распространению предметов роскоши и произведений искусства.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            14.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, высшее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь, январь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'macm'" id="macm">
                        <template #title>
                            MBA Art Collecting Management
                        </template>
                        <template #description>
                            <p>
                                Профессиональный курс для тех, кто стремится работать консультантами по искусству или работать в частных коллекциях, фондах или государственных учреждениях. Эта программа дает важные знания в области истории искусства, рынка искусства и управления коллекциями.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            14.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, высшее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь, январь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'ca'" id="ca">
                        <template #title>
                            MBA in Contemporary Art : Sales, Display and Collecting.
                        </template>
                        <template #description>
                            <p>
                                Профессиональное обучение для приобретения опыта в области современных произведений искусства, менеджмента и маркетинга.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            14.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, высшее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь, январь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'pam'" id="pam">
                        <template #title>
                            MBA in Performing Arts Management & Cultural Industries
                        </template>
                        <template #description>
                            <p>
                                Эта интенсивная одногодичная программа MBA готовит профессионалов для карьеры в сфере исполнительского искусства и индустрии культуры. Студенты получают представление о растущих требованиях культурного рынка в глобальном контексте посредством изучения структур культурных индустрий, управления исполнительскими видами искусства, истории перформанса, экономики культуры, а также дизайна и цифровых исследований.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            14.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, высшее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь, январь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'pg'" id="pg">
                        <template #title>
                            Подготовительный год на французском языке - Сорбонна
                        </template>
                        <template #description>
                            <p>
                               Программа «Французский язык и цивилизация» предоставляет иностранным студентам практические знания французского языка, а также методологические, исторические, стилистические, технические и профессиональные основы, необходимые для зачисления на одну из программ IESA Arts & Culture.
                            </p>
                        </template>
                        <template #duree>
                            6 месяцев - 1 год
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            4.150-9.800 евро/год 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            французский уровня B1, досье.
                        </template>
                        <template #start-time>
                            сентябрь, февраль
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'lu'" id="lu">
                        <template #title>
                            Подготовительный год на французском языке - Сорбонна
                        </template>
                        <template #duree>
                            1 месяц
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                           2.400 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                             английский уровня B2, досье.
                        </template>
                        <template #start-time>
                            июль
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    Кампус школы расположен в 11-м округе Парижа.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/watch?v=_3cUPwtLhYU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>